import Image from 'next/image'
import T from 'prop-types'
import { useRef, useState } from 'react'

import { getImageUrl } from '../../api/sanity'
import BEMHelper from '../../helpers/bem'
import { formatDate } from '../../helpers/date'
import Button from '../Button'
import Link from '../Link'
import styles from './Styles.module.scss'
const LINK_TYPES = {
  article: '/artikkel/',
}

const bem = new BEMHelper(styles)

const IMAGE_SIZES = {
  large: [800, 534],
  default: [300, 200],
}

const makeLink = ({ _type, url, slug, file }) => {
  const baseHref = LINK_TYPES[_type] || ''

  if (slug) {
    return `${baseHref}${slug}`
  }

  if (url) {
    return url
  }

  if (file) {
    return file?.asset?.url || ''
  }

  return ''
}

function Item({ large, square, ...item }) {
  const {
    title,
    preamble,
    image,
    source,
    level,
    publishedAt,
    date,
    loanArticle,
    loanArticleSource,
  } = item
  const href = makeLink(item)
  const published = publishedAt || date
  const imageSize = large || square ? IMAGE_SIZES.large : IMAGE_SIZES.default

  const src = image?.asset
    ? getImageUrl(image).width(imageSize[0]).height(imageSize[1]).format('jpg').url()
    : image

  return (
    <Link href={href} target="_blank" {...bem('item', { square })}>
      <div {...bem('content')}>
        <h3 {...bem('sub-title')}>{title}</h3>
        {(published || source || level) && (
          <span {...bem('meta')}>
            {source ||
              level ||
              (loanArticle && loanArticleSource && `${loanArticleSource}, `) ||
              (published && 'Tenk.') ||
              ''}{' '}
            {published && '- ' + formatDate(published)}
          </span>
        )}

        {preamble && large && <p {...bem('description')}>{preamble}</p>}
      </div>
      {src ? (
        <Image
          {...bem('image')}
          src={src}
          alt={image?.alt || ''}
          width={imageSize[0]}
          height={imageSize[1]}
        />
      ) : null}
    </Link>
  )
}

Item.propTypes = {
  _id: T.string,
  _key: T.string,
  slug: T.string,
  url: T.string,
  source: T.string,
  preamble: T.string,
  level: T.string,
  title: T.string,
  image: T.object,
  file: T.object,
  large: T.bool,
  square: T.bool,
}

export default function ResourceEntry({ title, preamble, items = [], large, fluid, ...props }) {
  const [showMore, setShowMore] = useState(false)
  const entriesRef = useRef(null)

  if (!items?.length) {
    return null
  }

  const displayedItems = showMore ? items : items.slice(0, 3)
  const remainingItems = items.length - displayedItems.length

  return (
    <section {...props} {...bem('', { large, fluid })} ref={entriesRef}>
      {title && (
        <header {...bem('header')}>
          <h2 {...bem('title')}>{title}</h2>
          <p {...bem('preamble')}>{preamble}</p>
        </header>
      )}

      <nav {...bem('list')}>
        {displayedItems.map((item) => {
          return <Item key={item._id || item._key} large={large} {...item} />
        })}
      </nav>

      {items.length > 3 && !showMore && (
        <Button onClick={() => setShowMore(true)} more>
          {remainingItems} flere
        </Button>
      )}
    </section>
  )
}

ResourceEntry.Item = Item

ResourceEntry.propTypes = {
  preamble: T.string.isRequired,
  title: T.string,
  items: T.arrayOf(T.shape(Item.propTypes)),
  large: T.bool,
  fluid: T.bool,
}
