import React from 'react'
import Image from 'next/image'
import SEO from '../SEO'
import NavList from '../NavList'
import ResourceEntry from '../ResourceEntry'
import YouTube from '../YouTube'
import Section from '../Section'
import Collaborators from '../Collaborators'
import { ContentSection } from '../Content'
import { TalkingEntry } from '../TalkingCards'
import { getImageUrl } from '../../api/sanity'

import { useFocusSimple as useFocus } from '../../hooks/useFocus'
import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)

export default function SubPage({ page }) {
  const sections = page.sections || []
  const menu = sections
    .filter((section) => section.showInMenu)
    .map((section) => {
      return {
        _key: section._key,
        title: section.title,
      }
    })
  const active = useFocus(menu.map(({ _key }) => _key))

  const imageUrl = page.image ? getImageUrl(page.image).width(800).url() : undefined

  return (
    <div {...bem('')}>
      <SEO
        title={page.title}
        description={page.preamble}
        path={page.slug?.current}
        image={page.ogImages?.facebook && getImageUrl(page.ogImages.facebook).url()}
      />
      <NavList active={active} inline items={menu} back={{ title: 'Hjem', label: '/' }} />

      <header {...bem('header', { image: page.image })}>
        <div {...bem('header-content')}>
          <h1 {...bem('title')}>{page.title}</h1>
          {page.preamble && <p {...bem('preamble')}>{page.preamble}</p>}
          {page.collaborators && page.collaborators.length > 0 && (
            <div {...bem('byline')}>
              {page.collaborators && (
                <>
                  <Collaborators collaborators={page.collaborators} />.
                </>
              )}
            </div>
          )}
        </div>
        {page.image && (
          <Image
            {...bem('image')}
            src={imageUrl}
            alt={page.image?.alt || ''}
            height={0}
            width={800}
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </header>

      <div {...bem('content')}>
        {sections.map((section) => {
          const cards = section.cards || []

          return (
            <Section
              key={section._key}
              id={section._key}
              title={section.title}
              preamble={section.description}
              type="stacked"
            >
              {cards.map((card) => {
                switch (card._type) {
                  case 'video': {
                    return <YouTube {...card} key={card._key} square />
                  }
                  case 'talkingType': {
                    return <TalkingEntry {...card} key={card._key} />
                  }
                  case 'article': {
                    if (card.slug) {
                      return <ResourceEntry.Item {...card} key={card._key} square />
                    }
                    return null
                  }
                  case 'sectionCard': {
                    return (
                      <div {...bem('section', 'full')}>
                        <ContentSection {...card} active={card._key} type="tight" />
                      </div>
                    )
                  }
                }
              })}
            </Section>
          )
        })}
      </div>
    </div>
  )
}
