import React from 'react'
import { useRouter } from 'next/router'
import ErrorPage from 'next/error'
import { groq } from 'next-sanity'

import SanityPreview from '../components/SanityPreview'
import SubPage from '../components/SubPage'
import { staticPageQuery } from '../api'
import { buildGetStaticPaths, buildGetStaticProps } from '../lib/page'

const query = groq`
  *[_type == "staticPage" && slug.current == $slug]${staticPageQuery} | order(_updatedAt desc)[0]
`

export default function StaticPage({ preview, pageData, error, slug }) {
  const router = useRouter()

  if (!router.isFallback && !pageData) {
    return <ErrorPage statusCode={404} />
  }

  if (error) {
    return <ErrorPage statusCode={error.statusCode} title={error.title} />
  }

  return (
    <SanityPreview preview={preview} initialData={pageData} query={query} params={{ slug }}>
      {(page) => {
        if (!page) {
          return <></>
        }
        return <SubPage page={page} />
      }}
    </SanityPreview>
  )
}

export const getStaticProps = buildGetStaticProps(query)

export const getStaticPaths = buildGetStaticPaths('staticPage')
